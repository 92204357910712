.container {
}

.header {
  mix-blend-mode: difference;
  color: var(--light);
  z-index: 99;
}
.footer {
  margin-top: 10vh;
  border-top: 1px solid var(--font-opac-lo);
}

.menu,
.version {
  z-index: 99;
  color: var(--font);
  background-color: var(--dark-opac-hi);
  /* backdrop-filter: blur(calc(3 * var(--spacing)));
  -webkit-backdrop-filter: blur(calc(3 * var(--spacing))); */
}

.appHeader {
  position: fixed;
  top: 0;
  z-index: -10;
  width: 100vw;
  height: 100vh;
}
.home {
  /* z-index: -20; */
}
.workDetails,
.artDetails {
  /* z-index: 6;  */
}
