.heroSection {
  position: relative;
  width: 100vw;
}

.details {
  margin-top: 40vh;
}

@media screen and (max-width: 600px) {
  .projectHeroMediaContainer {
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .details {
    margin-top: 5vh;
  }
}
