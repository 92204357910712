.sectionDisc {
  text-align: center;
  font-weight: 500;
  padding: var(--spacing);
  width: calc(100% - (2 * var(--spacing)));
  margin: var(--spacing);
}
.sectionDisc > div {
  color: var(--accent);
}
