.container {
  background-color: var(--bg);
  color: var(--font);
  border: 1px solid var(--font-opac-lo);
  bottom: var(--spacing);
  left: var(--spacing);
  width: calc(100% - (2 * var(--spacing)));
  padding: var(--spacing) var(--spacing) calc(0.5 * var(--spacing));
}
.heading {
  margin-bottom: calc(2 * var(--spacing));
}

.container > div {
  width: 100%;
}
.navigate {
  width: 100%;
}
.container > div > * {
  width: 100%;
  padding: calc(0.5 * var(--spacing)) 0;
  border-bottom: 1px solid var(--font-opac-ulo);
}

.container > div *:last-child {
  border-bottom: 1px solid var(--font-opac-hi);
}

.container > div:last-child *:last-child {
  border-bottom: none;
}
