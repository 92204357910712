.container {
  /* margin-bottom: 40vh; */
}

.categorySet {
  margin-bottom: calc(5 * var(--spacing));
}
.setHeader {
  margin-bottom: calc(3 * var(--spacing));
}
.linkItem {
  border-bottom: 1px solid var(--font-opac-ulo);
}
.setItem > div:last-child {
  border-bottom: none;
}
.itemContent {
  height: max-content;
  padding: var(--spacing) 0;
}

.itemText {
  width: 100%;
  flex-direction: row;
}

.itemContent > img {
  border-radius: calc(0.25 * var(--spacing));
}
.itemLogo {
  width: auto;
  height: calc(1.15 * var(--fs-body));
  aspect-ratio: 1/1;
  object-fit: contain;
}
.itemLogo,
.itemArrow {
  flex-basis: calc(1.15 * var(--fs-body));
}
.itemText {
  height: max-content;
}

.itemContent {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .itemText {
    width: 100%;
    flex-direction: column;
  }
  .itemDesc {
    margin-top: calc(0.25 * var(--spacing));
  }
}
