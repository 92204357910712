.container {
  width: 100%;
}
@media screen and (max-width: 600px) {
  .container {
    width: 100%;
    height: calc(40vh - var(--header-height) - calc(1 * var(--spacing)));
  }
}

.controlBar {
  position: fixed;
  top: var(--header-height);
  padding: var(--spacing);
  padding-top: 0;
  mix-blend-mode: difference;
  color: var(--light);
  width: 100vw;
}
.container > div:first-child {
  padding-bottom: calc(0.5 * var(--spacing));
}

.infoContainer {
  position: fixed;

  z-index: 20;
  transform: translate(0%, calc(-50% - 5vh));
  top: 30%;
  transition: opacity var(--speed-2) var(--ease),
    filter var(--speed-2) var(--ease), transform var(--speed-2) var(--ease);
}

.infoTitle {
  transition: opacity var(--speed-2) var(--ease),
    filter var(--speed-2) var(--ease), transform var(--speed-2) var(--ease);
  width: 100%;
}

.titleText {
}

.infoDesc {
  height: calc(3 * (var(--fs-body) * 1.25));
  transition: transform var(--speed-3) var(--ease);
}
.descText {
  /* text-shadow: 0 0 calc(3 * var(--spacing)) var(--dark-opac-hi); */
}
