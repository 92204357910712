.container {
  background-color: var(--bg);
  color: var(--font);
  border: 1px solid var(--font-opac-lo);
}

.headerText .title {
  margin-bottom: calc(0.5 * var(--spacing));
}

.container > div {
  width: 100%;
}
.container > div {
  width: 100%;
}
.body {
  height: 100%;
  overflow-y: scroll;
}
.themeItem > * {
  margin-bottom: calc(1 * var(--spacing));
}

.itemThumbnail {
  position: relative;
  object-fit: contain;
  aspect-ratio: 1200 / 630;
}
.itemThumbnail > img {
  border: 1px solid var(--font-opac-lo);
}
.itemTags {
  text-transform: capitalize;
}

.note {
  margin-top: calc(6 * var(--spacing));
}

/*(600px and below) */
@media only screen and (max-width: 600px) {
  .themeItem {
    display: block;
  }
  .itemTitle {
    flex-direction: row;
    height: auto;
  }
}
/*  (600px and above) */
@media only screen and (min-width: 600px) {
  .themeItem {
    display: block;
  }
  .itemTitle {
    flex-direction: row;
    height: auto;
  }
}
/*  (900px and above) */
@media only screen and (min-width: 900px) {
  .themeItem {
    display: flex;
    flex-direction: row;
  }
  .itemTitle {
    flex-direction: column;
    height: 40%;
  }
}

/* (1200px and above) */
@media only screen and (min-width: 1200px) {
  .themeItem {
    display: flex;
    flex-direction: row;
  }
  .itemTitle {
    flex-direction: column;
    height: 40%;
  }
}
