.container {
  padding-top: 0;
  padding-bottom: 0;
}

/* GLOBALLY SAME TILL HERE */

.artworksContainer {
  column-count: 2;
  column-gap: var(--spacing);
}
@media screen and (max-width: 768px) {
  .artworksContainer {
    column-count: 1;
    column-gap: var(--spacing);
  }
}
.artItemContainer {
  display: inline-block;
  margin-bottom: var(--spacing);
  width: 100%;
}

.itemText {
  padding-top: calc(var(--spacing) * 0.5);
}
