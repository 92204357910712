.container {
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.loaded {
  bottom: 30%;
}
