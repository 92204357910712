.container {
  position: relative;
}
.number {
  transition: transform var(--speed-2) var(--bounce);
  height: calc(100vh - (2 * var(--header-height)));
}
.number > div {
  transition: all var(--speed-2) var(--ease);
}

.indicator {
  height: 2px;
  width: 100%;
  transition: all var(--speed-g) linear;
}

.content {
  height: 100px;

  width: 100%;
  filter: blur(calc(1 * var(--spacing)));
  transition: transform var(--speed-2) var(--bounce);
  background-image: linear-gradient(-90deg, #eed200, #005fee, #f94108);
  /* background-color: var(--font-opac-mid); */
}
