@import url("typography.css");
@import url("wipe_local.css");
@import url("grid-layout.min.css");
@import url("flex-layout.min.css");

:root {
  /* to be defined */
  --light: rgb(255, 255, 255);
  --dark: rgb(0, 0, 0);
  /* opacity points */
  --opac-ulo: 0.1;
  --opac-lo: 0.25;
  --opac-mid: 0.4;
  --opac-hi: 0.6;
  --opac-uhi: 0.8;
  --dark-transp: rgba(0, 0, 0, 0);
  --light-transp: rgba(255, 255, 255, 0);
  --dark-opac-ulo: rgba(0, 0, 0, var(--opac-ulo));
  --light-opac-ulo: rgba(255, 255, 255, var(--opac-ulo));
  --dark-opac-lo: rgba(0, 0, 0, var(--opac-lo));
  --light-opac-lo: rgba(255, 255, 255, var(--opac-lo));
  --dark-opac-mid: rgba(0, 0, 0, var(--opac-mid));
  --light-opac-mid: rgba(255, 255, 255, var(--opac-mid));
  --dark-opac-hi: rgba(0, 0, 0, var(--opac-hi));
  --light-opac-hi: rgba(255, 255, 255, var(--opac-hi));
  --dark-opac-uhi: rgba(0, 0, 0, var(--opac-uhi));
  --light-opac-uhi: rgba(255, 255, 255, var(--opac-uhi));
  --accent: #f94108;
  --spacing: 0.75rem;
  --blur: 4px;
  --cubic-bezier: cubic-bezier(0.65, 0, 0.35, 15);
  --ease: cubic-bezier(0.43, 0.13, 0.23, 0.96);
  --bounce: cubic-bezier(0.175, 0.885, 0.32, 1.05);
  --speed-0: 100ms;
  --speed-g: 300ms;
  --speed-2: 750ms;
  --speed-3: 1000ms;
  /* calculated */

  /* --bar-height: calc((1 * var(--spacing) + (var(--fs-heading-1) * 1.05))); */
  --header-height: calc((2 * var(--spacing) + (var(--fs-caption) * 1.15)));
  /* --negAppbH: calc(0 - var(--appbar-height)); */
}

@media screen and (max-width: 600px) {
  :root {
    /* --spacing: 1rem; */
    --spacing: 1rem;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  gap: var(--spacing);
  padding: var(--spacing);
}
.no-padding {
  padding: 0 !important;
}
.gap {
  gap: var(--spacing);
}
.no-gap {
  gap: 0 !important;
}

[data-theme="light"] {
  --base: white;
  --font: var(--dark);
  --bg: var(--light);
  --font-transp: var(--dark-transp);
  --bg-transp: var(--light-transp);
  --font-opac-ulo: var(--dark-opac-ulo);
  --bg-opac-ulo: var(--light-opac-ulo);
  --font-opac-lo: var(--dark-opac-lo);
  --bg-opac-lo: var(--light-opac-lo);
  --font-opac-mid: var(--dark-opac-mid);
  --bg-opac-mid: var(--light-opac-mid);
  --font-opac-hi: var(--dark-opac-hi);
  --bg-opac-hi: var(--light-opac-hi);
  --font-opac-uhi: var(--dark-opac-uhi);
  --bg-opac-uhi: var(--light-opac-uhi);
  --blend: var(--light);
}
[data-theme="dark"] {
  --base: black;
  --font: var(--light);
  --bg: var(--dark);
  --font-transp: var(--light-transp);
  --bg-transp: var(--dark-transp);
  --font-opac-ulo: var(--light-opac-ulo);
  --bg-opac-ulo: var(--dark-opac-ulo);
  --font-opac-lo: var(--light-opac-lo);
  --bg-opac-lo: var(--dark-opac-lo);
  --font-opac-mid: var(--light-opac-mid);
  --bg-opac-mid: var(--dark-opac-mid);
  --font-opac-hi: var(--light-opac-hi);
  --bg-opac-hi: var(--dark-opac-hi);
  --font-opac-uhi: var(--light-opac-uhi);
  --bg-opac-uhi: var(--dark-opac-uhi);
  --blend: var(--light);
}

* {
  box-sizing: border-box;
  cursor: none;
}
*::selection {
  color: var(--accent);
  background: transparent;
}

html {
  scroll-behavior: smooth;
  overscroll-behavior-x: none;
  /* Support for Safari and Chrome. */

  -webkit-tap-highlight-color: transparent;
}

body {
  overscroll-behavior-x: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0 calc(0 * (var(--spacing)));
  padding: 0;
  background-color: var(--bg);
  color: var(--font);
}
p {
  margin: 0;
  margin-bottom: var(--spacing);
}
ol,
ul {
  list-style: disc;
  margin-left: calc(1.2 * var(--spacing));
}

li {
  text-decoration: none;
}
a,
a:visited,
a:hover,
a:active,
a:focus {
  color: inherit;
  text-decoration: none;
}

/* .underline {
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-color: var(--font);
  text-decoration-thickness: 1px;
  -mox-text-decoration-line: underline;
  -mox-text-decoration-style: solid;
  -mox-text-decoration-color: var(--font);
  -mox-text-decoration-thickness: 1px;
  -webkit-text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  -webkit-text-decoration-color: var(--font);
  -webkit-text-decoration-thickness: 1px;
} */

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  background: var(--dark);
}

::-webkit-scrollbar-thumb {
  background: var(--font);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--font);
}

/*
.scrollbar-track{
  background-color: transparent !important;
}

.scrollbar-track-y{
  width:12px !important;
}


.scrollbar-thumb{
  width:12px !important;
  background-color: black !important;
  border-radius: 0 !important;
}

 */

/* type text height fillers in grid containers */
/* .fs-body-filler {
  grid-column: 1 / span 8;
  height: calc(var(--fs-body) + var(--spacing));
}
.fs-heading-0-filler {
  grid-column: 1 / span 8;
  height: calc(var(--fs-heading-0) + var(--spacing));
}
.fs-heading-1-filler {
  grid-column: 1 / span 8;
  height: calc(var(--fs-heading-1) + var(--spacing));
}
.fs-heading-2-filler {
  grid-column: 1 / span 8;
  height: calc(var(--fs-heading-2) + var(--spacing));
}
.fs-heading-3-filler {
  grid-column: 1 / span 8;
  height: calc(var(--fs-heading-3) + var(--spacing));
}
.fs-heading-4-filler {
  grid-column: 1 / span 8;
  height: calc(var(--fs-heading-4) + var(--spacing));
}
.fs-heading-x-filler {
  grid-column: 1 / span 8;
  height: calc(var(--fs-heading-x) + var(--spacing));
}
.fs-heading-xx-filler {
  grid-column: 1 / span 8;
  height: calc(var(--fs-heading-xx) + var(--spacing));
} */

.has-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.icon {
  color: var(--bg);
  margin: 0 calc(var(--spacing) / 4);
}
.icon-md {
  font-size: inherit;
}

.transparent {
  opacity: 0;
}
.opac-lo {
  opacity: var(--opac-lo);
}
.opac-mid {
  opacity: var(--opac-mid);
}
.opac-hi {
  opacity: var(--opac-hi);
}
.opac-uhi {
  opacity: var(--opac-uhi);
}
.opaque {
  opacity: 1;
}

.cursor-path {
  fill: var(--font);
}

.loader {
  padding: var(--spacing);
  /* transition: all var(--speed-0) var(--cubic-bezier); */
  position: absolute;
  height: 100vh;
  width: 100vw;
  color: var(--font);
  top: 0;
  left: 0;
  z-index: 99;
  background-color: var(--bg);
  opacity: 0.25;
}
.o-h {
  overflow: hidden;
}

.b-r {
  border: 1px solid red;
}
.b-b {
  border: 1px solid blue;
}
.ta-j {
  text-align: justify;
}
.ta-r {
  text-align: right;
}
.ta-c {
  text-align: center;
}

.work-details-page {
  position: absolute;
}
.work-details-page-enter {
  /* transform: translateY(100%); */
  height: 20px;
}

.work-details-page-enter-active {
  /* transform: translateY(0); */
  height: calc(100vh - var(--bar-height));
  transition: height var(--speed-g) var(--ease),
    transform var(--speed-g) var(--ease);
}

.work-details-page-exit {
  /* transform: translateY(0); */
  height: calc(100vh - var(--bar-height));
}

.work-details-page-exit-active {
  /* transform: translateY(100%); */
  height: 20px;
  transition: height var(--speed-g) var(--ease),
    transform var(--speed-g) var(--ease);
}
.img-wrap {
  overflow: hidden;
  border-radius: calc(0 * var(--spacing));
}

svg,
path {
  fill: var(--font);
  stroke: var(--font);
}

iframe {
  width: 100%;
  height: 100%;
}

.drilldown-page > div {
  width: 50%;
  margin: auto;
  padding-bottom: calc(1.5 * var(--spacing));
}
.drilldown-page .heading {
  margin-top: calc(5 * var(--spacing));
  margin-bottom: calc(1 * var(--spacing));
}
.drilldown-page .sub-heading {
  margin-top: calc(2 * var(--spacing));
  margin-bottom: calc(0 * var(--spacing));
}
.drilldown-page .image {
  margin: calc(2 * var(--spacing)) auto;
}
.drilldown-page .img-sm {
  width: 50%;
  margin: calc(2 * var(--spacing)) auto;
}
.drilldown-page .img-lg {
  width: 100%;
  margin: calc(3 * var(--spacing)) auto;
}

/*(600px and below) */
@media only screen and (max-width: 600px) {
  .drilldown-page > div {
    width: 100%;
    margin: auto;
    padding-top: var(--spacing);
  }
  .drilldown-page .image-small {
    width: 100%;
  }
}
/*  (600px and above) */
@media only screen and (min-width: 600px) {
  .drilldown-page > div {
    width: 80%;
    margin: auto;
    padding-top: var(--spacing);
  }
  .drilldown-page .image-small {
    width: 100%;
  }
}
/*  (900px and above) */
@media only screen and (min-width: 900px) {
  .drilldown-page > div {
    width: 65%;
    margin: auto;
    padding-top: var(--spacing);
  }
  .drilldown-page .image-small {
    width: 100%;
  }
}

/* (1200px and above) */
@media only screen and (min-width: 1200px) {
  .drilldown-page > div {
    width: 50%;
    margin: auto;
    padding-top: var(--spacing);
  }
  .drilldown-page .image-small {
    width: 100%;
  }
}

.padding {
  padding: var(--spacing);
}

/* width (vw) */
.w-5 {
  width: 5vw;
}
.w-10 {
  width: 10vw;
}
.w-15 {
  width: 15vw;
}

.w-20 {
  width: 20vw;
}

.w-30 {
  width: 30vw;
}

.w-40 {
  width: 40vw;
}

.w-45 {
  width: 45vw;
}

.w-50 {
  width: 50vw;
}

.w-60 {
  width: 60vw;
}

.w-70 {
  width: 70vw;
}
.w-80 {
  width: 80vw;
}
.w-90 {
  width: 90vw;
}
.w-100 {
  width: 100vw;
}

/* width (%) */
.w-5p {
  width: 5%;
}
.w-10p {
  width: 10%;
}
.w-15p {
  width: 15%;
}

.w-20p {
  width: 20%;
}

.w-30p {
  width: 30%;
}

.w-40p {
  width: 40%;
}

.w-45p {
  width: 45%;
}

.w-50p {
  width: 50%;
}

.w-60p {
  width: 60%;
}

.w-70p {
  width: 70%;
}
.w-80p {
  width: 80%;
}
.w-90p {
  width: 90%;
}
.w-100p {
  width: 100%;
}

/* height (vh) */

.h-10 {
  height: 10vh;
}
.h-15 {
  height: 15vh;
}
.h-20 {
  height: 20vh;
}

.h-30 {
  height: 30vh;
}
.h-35 {
  height: 35vh;
}

.h-40 {
  height: 40vh;
}

.h-45 {
  height: 45vh;
}

.h-50 {
  height: 50vh;
}

.h-55 {
  height: 55vh;
}

.h-60 {
  height: 60vh;
}

.h-70 {
  height: 70vh;
}
.h-80 {
  height: 80vh;
}
.h-90 {
  height: 90vh;
}
.h-100 {
  height: 100vh;
}

/* height (%) */

.h-10p {
  height: 10%;
}
.h-15p {
  height: 15%;
}
.h-20p {
  height: 20%;
}

.h-30p {
  height: 30%;
}
.h-35p {
  height: 35%;
}

.h-40p {
  height: 40%;
}

.h-45p {
  height: 45%;
}

.h-50p {
  height: 50%;
}

.h-55p {
  height: 55%;
}

.h-60p {
  height: 60%;
}

.h-70p {
  height: 70%;
}
.h-80p {
  height: 80%;
}
.h-90p {
  height: 90%;
}
.h-100p {
  height: 100%;
}

/* @media screen and (max-width: 600px) {
  .w-10,
  .w-20,
  .w-30,
  .w-40,
  .w-45,
  .w-50,
  .w-60,
  .w-70,
  .w-80,
  .w-90,
  .w-100 {
    width: auto;
  }
} */

.t {
  top: 0;
}
.l {
  left: 0;
}
.r {
  right: 0;
}
.b {
  bottom: 0;
}

.pos-a {
  position: absolute;
}
.pos-f {
  position: fixed;
}
.pos-s {
  position: sticky;
  position: -webkit-sticky;
}

.highlight {
  /* color: var(--accent); */
  background-image: linear-gradient(
    120deg,
    var(--font-transp),
    var(--font-transp),
    var(--font-transp),
    var(--font-opac-lo),
    var(--font-transp),
    var(--font-opac-lo),
    var(--font-transp),
    var(--font-transp),
    var(--font-transp)
  );
}

.none {
  display: none;
}

video,
img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: 50% 50%;
  -o-object-position: 50% 50%;
  user-select: none;
  pointer-events: none;
}

.zi_-10 {
  z-index: -10;
}
.zi_10 {
  z-index: 10;
}

.t-s {
  text-shadow: 0px 0px 2px var(--dark-opac-lo);
}

.vis-h {
  visibility: hidden;
}

.cursor {
  position: fixed;
  mix-blend-mode: difference;
  width: 32px;
  height: 32px;
  z-index: 999;
  pointer-events: none;
}
.cursor-shape {
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  transition: all var(--speed-g) var(--bounce);
}
.cursor-lines {
  height: 100%;
  width: 100%;
}
.cursor-lines > div {
  position: absolute;
  background-color: var(--light);
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.cursor-line-horizontal {
  height: 1.5px;
  width: 100%;
}

.cursor-line-vertical {
  height: 100%;
  width: 1.5px;
}
.cursor-text {
  transform: translate(50%, 100%);
  color: var(--light);
}
