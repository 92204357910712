.links {
  padding: 0 var(--spacing);
}

.primary > *,
.social > *,
.themeToggle > * {
  margin: var(--spacing) 0;
}
.samarthJajodia > * {
  padding: var(--spacing) 0;
}
.menuContent {
  display: inline-block;
  padding: var(--spacing) 0;
}

.primary > span:last-child {
  display: none;
}

.toggles {
  margin: var(--spacing) 0;
}
