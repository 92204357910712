.container {
}
.container > div {
  margin-bottom: calc(2 * var(--spacing));
}
.magicText {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 5vh;
}

.magicText > span {
  display: inline-flex;
}
.magicMedia {
  transition: all var(--speed-2) var(--ease);
}
.magicLink {
  transform: translateY(7vh);
}

/*(600px and below) */
@media only screen and (max-width: 600px) {
  .magicMedia {
    height: auto;
    width: calc((100% - (3 * var(--spacing))) / 4);
    aspect-ratio: 1/1;
  }
}
/*  (600px and above) */
@media only screen and (min-width: 600px) {
  .magicMedia {
    height: auto;
    width: calc((100% - (5 * var(--spacing))) / 6);
    aspect-ratio: 1/1;
  }
}
/*  (900px and above) */
@media only screen and (min-width: 900px) {
  .magicMedia {
    height: auto;
    width: calc((100% - (7 * var(--spacing))) / 8);
    aspect-ratio: 1/1;
  }
}

/* (1200px and above) */
@media only screen and (min-width: 1200px) {
  .magicMedia {
    height: auto;
    width: calc((100% - (9 * var(--spacing))) / 10);
    aspect-ratio: 1/1;
  }
}
.magicGallery {
  position: relative;
  z-index: -1;
}
.magicGallery::after {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(var(--bg-transp), var(--bg) 65%);

  bottom: -20%;
  left: 0;
}
