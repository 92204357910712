.spinnerWrapper {
  position: relative;
  animation: blur 5s ease-in-out infinite;
  user-select: none;
}
.spinner1 {
  animation: zoom 4s ease infinite;
  mix-blend-mode: screen;
  z-index: -10;
}
.spinner3 {
  animation: zoom 5s linear infinite reverse;
  mix-blend-mode: overlay;
}
.spinner2 {
  animation: zoom2 2s linear infinite alternate-reverse;
  mix-blend-mode: overlay;
}
.path {
  background: conic-gradient(
    from 180deg at 50% 50%,
    #eed200 0deg,
    #005fee 120deg,
    var(--bg) 240deg,
    #eed200 360deg
  );
  height: calc(18rem);
  width: calc(18rem);
  border-radius: 50%;
}
.spinner1 > .path {
  animation: spin 3s linear infinite reverse;
}
.spinner2 > .path {
  animation: spin 4s ease-in-out infinite alternate-reverse;
}
.spinner3 > .path {
  animation: spin 2s linear infinite;
}
.spinner3 > .path {
  background: conic-gradient(
    from 180deg at 50% 50%,
    #f94108 0deg,
    var(--bg) 120deg,
    #f94108 240deg,
    #005fee 360deg
  );
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes zoom {
  0% {
    transform: scale(0.4);
  }
  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.4);
  }
}
@keyframes zoom2 {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(0.4);
  }
  100% {
    transform: scale(0.2);
  }
}
@keyframes blur {
  0% {
    filter: blur(35px);
  }
  50% {
    filter: blur(55px);
  }
  100% {
    filter: blur(35px);
  }
}
