.heroSection {
  position: relative;
  width: 100vw;
}

.dataGrid {
  margin-top: calc(2 * var(--spacing));
}
.itemMedia > div {
  margin-bottom: calc(3 * var(--spacing));
}

.itemMedia {
  width: 50%;
  height: auto;
  margin: auto;
  min-height: 40vh;
  /* /* padding: 8vh 0 3vh; */
  margin-top: 40vh;
  border-bottom: 1px solid var(--font-opac-mid);
}

.itemMediaContent {
  transition: transform var(--speed-2) var(--ease);
  transform: scale(1.02);
}

/* for mobile */

/*(600px and below) */
@media only screen and (max-width: 600px) {
  .itemMedia {
    width: 100%;
    height: auto;
    margin: auto;
    min-height: 40vh;
    /* padding: 8vh 0 3vh; */
    margin-top: 40vh;
    border-bottom: 1px solid var(--font-opac-mid);
  }
}
/*  (600px and above) */
@media only screen and (min-width: 600px) {
  .itemMedia {
    width: 80%;
    height: auto;
    margin: auto;
    min-height: 40vh;
    /* padding: 8vh 0 3vh; */
    margin-top: 40vh;
    border-bottom: 1px solid var(--font-opac-mid);
  }
}
/*  (900px and above) */
@media only screen and (min-width: 900px) {
  .itemMedia {
    width: 65%;
    height: auto;
    margin: auto;
    min-height: 40vh;
    /* padding: 8vh 0 3vh; */
    margin-top: 40vh;
    border-bottom: 1px solid var(--font-opac-mid);
  }
}

/* (1200px and above) */
@media only screen and (min-width: 1200px) {
  .itemMedia {
    width: 50%;
    height: auto;
    margin: auto;
    min-height: 40vh;
    /* padding: 8vh 0 3vh; */
    margin-top: 40vh;
    border-bottom: 1px solid var(--font-opac-mid);
  }
}
