.container {
}

.heroContainer {
  top: var(--header-height);
  z-index: -10;
  width: 100%;
  transition: filter var(--speed-2) var(--bounce),
    transform var(--speed-2) var(--bounce), opacity var(--speed-2) var(--bounce);
}

.gallery {
  padding-top: 55vh;
}
.magic {
  transform: translateY(-25vh);
}

/* for mobile */

/*(600px and below) */
@media only screen and (max-width: 600px) {
  .heroTitle {
    font-size: calc(100vh / 22);
    line-height: 0.9;
    padding: 0 var(--spacing);
    font-weight: 600;
    height: calc(10 * (100vh / 22));
    margin: 0 auto;
    letter-spacing: 1px;

    width: 100%;
  }
  .catchLine {
    transition: filter var(--speed-2) var(--bounce),
      transform var(--speed-2) var(--bounce),
      opacity var(--speed-2) var(--bounce);
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }
}
/*  (600px and above) */
@media only screen and (min-width: 600px) {
  .heroTitle {
    font-size: calc(100vh / 19);
    line-height: 0.9;
    padding: 0 var(--spacing);
    font-weight: 600;
    margin: 0 auto;
    letter-spacing: 0.2px;

    width: 100%;
  }
  .catchLine {
    transition: filter var(--speed-2) var(--bounce),
      transform var(--speed-2) var(--bounce),
      opacity var(--speed-2) var(--bounce);
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
}
/*  (900px and above) */
@media only screen and (min-width: 900px) {
  .heroTitle {
    font-size: calc(100vh / 15);
    line-height: 0.9;
    padding: 0 var(--spacing);
    font-weight: 600;
    margin: 0 auto;
    letter-spacing: 0.2px;

    width: 100%;
  }
  .catchLine {
    transition: filter var(--speed-2) var(--bounce),
      transform var(--speed-2) var(--bounce),
      opacity var(--speed-2) var(--bounce);
    width: 65%;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* (1200px and above) */
@media only screen and (min-width: 1200px) {
  .heroTitle {
    font-size: calc(100vh / 12);
    line-height: 0.9;
    padding: 0 var(--spacing);
    margin: 0 auto;
    font-weight: 600;
    letter-spacing: 0.2px;

    width: 100%;
  }
  .catchLine {
    transition: filter var(--speed-2) var(--bounce),
      transform var(--speed-2) var(--bounce),
      opacity var(--speed-2) var(--bounce);
    width: 50%;
    left: 50%;
    transform: translateX(-50%);
  }
}
