.sectionDisc {
  text-align: center;
  font-weight: 500;
  padding: var(--spacing);
  width: calc(100% - (2 * var(--spacing)));
  background-color: rgba(228, 65, 65, 0.2);
  margin: var(--spacing);
}
.sectionDisc > div {
  color: var(--accent);
}
