.sectionDisc {
  text-align: center;
  font-weight: 500;
  padding: var(--spacing);
  width: calc(100% - (2 * var(--spacing)));
  background-color: rgba(228, 65, 65, 0.2);
  margin: var(--spacing);
}
.sectionDisc > div {
  color: var(--accent);
}
.tempItem {
  position: relative;
  transform: translate(-50%, 0);
  left: 50%;
  width: 80vw;
  height: 80vh;
}
.ss {
  padding: var(--spacing);
}
.tempItem > iframe {
  height: 100%;
  width: 100%;
}
.figmaTempItem {
  position: relative;
  transform: translate(-50%, 0);
  left: 50%;
  width: 411px;
  height: 823px;
}
iframe {
  border: none;
}
