.container {
}

.wrapper {
  column-count: 3;
  column-gap: var(--spacing);
}

.categorySet {
  break-inside: avoid-column;
  margin-bottom: calc(5 * var(--spacing));
}
.setHeader {
  margin-bottom: calc(3 * var(--spacing));
}
.toolList {
  width: 100%;
}
.setItem {
  height: calc(8 * var(--spacing));
  margin-bottom: calc(1 * var(--spacing));
}
.toolItem {
  width: 100%;
  height: 100%;
}
.itemLogo {
  width: auto;
  height: 50%;
  aspect-ratio: 1/1;
  object-fit: contain;
}
.itemText {
  width: 100%;
}

.itemAnchor {
  width: 100%;
}
.itemText > div {
  margin-top: calc(0.8 * var(--spacing));
  width: 85%;
}

@media screen and (max-width: 900px) {
  .setItem {
    height: calc(4 * var(--spacing));
    margin-bottom: calc(1 * var(--spacing));
  }
  .wrapper {
    column-count: 1;
  }
  .itemLogo {
    width: auto;
    height: 90%;
    aspect-ratio: 1/1;
    object-fit: contain;
  }
  .itemText > div {
    margin-top: calc(0.5 * var(--spacing));
    width: 85%;
  }
}
