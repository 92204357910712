/*(600px and below) */
@media only screen and (max-width: 600px) {
  .item > div:first-child {
    width: 20vw;
  }
}
/*  (600px and above) */
@media only screen and (min-width: 600px) {
  .item > div:first-child {
    width: 15vw;
  }
}
/*  (900px and above) */
@media only screen and (min-width: 900px) {
  .item > div:first-child {
    width: 10vw;
  }
}

/* (1200px and above) */
@media only screen and (min-width: 1200px) {
  .item > div:first-child {
    width: 5vw;
  }
}
